<template>
  <div style="background: #F5FFEF;overflow: auto;"  class="container">
    <div class="bottom">
      <img src="../../static/bottomlogo.png" />
    </div>
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="3"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content >
  <div  class="schools_content">
    <div class="producetitle">
      <h3>
        入驻学校
      </h3>
      <div class="newslinecontent">
        <div class="newsline"></div>
        <div class="newsline"></div>
      </div>
    </div>
    <div class="lunbotu">
      <swiper :options="swiperOption"> 
        <swiper-slide class="swiper-slide" v-for="(item,index) in newdata" :key="index">
          <div class="contentswiper">
            <div class="xuexiaoc">
              <img :src="item.ListImage" />
              <div class="xuexiaozz">                                                                                                                                                                       

              </div>
              <h3>{{item.Title}}</h3>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"  ><div class="prevbtn"><img src="../../static/left.png" /></div> </div>  <!-- 箭头左 -->

      <div class="swiper-button-next"  ><div class="nextbtn"><img src="../../static/right.png" /></div></div>  <!-- 箭头右 -->
    </div>
  </div>
      <div class="schools_list">
        <div class="schools_item">
          <div class="schools_title">
            <h3>海淀区永泰小学</h3>
          </div>
  
          <div class="schools_title">
            <h3>东升实验小学</h3>
          </div>
          <div class="schools_title">
            <h3>中关村一小</h3>
          </div>
          <div class="schools_title">
            <h3>首师大附属顺义实验小学</h3>
          </div>
        </div>
      <div class="schools_item">
        <div class="schools_title">
          <h3>海淀北部实验学校</h3>
        </div>
        <div class="schools_title">
          <h3>石油小学</h3>
        </div>
        <div class="schools_title">
          <h3>木林三小</h3>
        </div>
        <div class="schools_title">
          <h3>北京理工大学附属小学</h3>
        </div>
      </div>

        <div class="schools_item">
          <div class="schools_title">
            <h3>北京华电</h3>

          </div>
          <div class="schools_title">
            <h3>海淀区清河四小</h3>
          </div>
          <div class="schools_title">
            <h3> 昌平第二小学</h3>
          </div>
          <div class="schools_title">
            <h3>方家胡同小学</h3>
          </div>
        </div>
        <div class="schools_item">
          <div class="schools_title">
            <h3>海淀区西苑小学</h3>
          </div>
          <div class="schools_title">
            <h3>培星小学</h3>
          </div>
          <div class="schools_title">
            <h3>海淀区翠微小学</h3>
          </div>
          <div class="schools_title">
            <h3>蒲黄榆第一小学</h3>
          </div>
        </div>
         <div class="schools_item">
          <div class="schools_title">
            <h3>上海虹口广中路小学</h3>
          </div>
          <div class="schools_title">
            <h3>顺义张镇中心小学</h3>
          </div>
          <div class="schools_title">
            <h3>芳星园小学</h3>
          </div>
          <div class="schools_title">
            <h3>西府冠华</h3>
          </div>
        </div>
        <div class="schools_item">
          <div class="schools_title">
            <h3>海淀区第三实验小学</h3>
          </div>
          <div class="schools_title">
            <h3>北京育鸿学校</h3>
          </div>
          <div class="schools_title">
            <h3>顺义一中附小</h3>
          </div>
          <div class="schools_title">
            <h3>丰台区角门小学</h3>
          </div>
        </div>
        <div class="schools_item">
          <div class="schools_title">
            <h3>昌平实验小学</h3>
          </div>
          <div class="schools_title">
            <h3>丰台万年花城分校</h3>
          </div>
          <div class="schools_title">
            <h3>丰台区四合庄小学</h3>
          </div>
          <div class="schools_title">
            <h3>中国农业大学附属小学</h3>
          </div>
        </div>
        <div class="schools_item">
          <div class="schools_title">
            <h3>西城区白纸坊小学</h3>
          </div>
          <div class="schools_title">
            <h3>丰台区芳群园第一小学</h3>
          </div>
          <div class="schools_title">
            <h3>怀柔区第三小学</h3>
          </div>
          <div class="schools_title">
            <h3>中央民大附中丰台实验学校</h3>
          </div>
        </div>
   
      </div>

    </a-layout-content>

    <a-layout-footer style="padding: 0;bottom: 0;width: 100%;">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>

</template>

<script>

import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "newlogin",
  components: {
    loginheader: loginheader,
    loginfooter: loginfooter,

  },data(){
    return {
      newdata: [
        {
          Id: 'a15b0b5d-ea94-e832-d2da-9b7900af5cde',
          Title: '理工附属小学',
          ListImage: '../../static/ligongfushu.jpg'
        },
        {
          Id: 'e617452d-72a4-1e4f-e61d-12372b90f6d5',
          Title: '昌平实验小学',
          ListImage: '../../static/changpingshiyan.jpg'
        },
        {
          Id: 'e336bcfb-2ce3-0783-d042-92e22a6da6d0',
          Title: '育新小学',
          ListImage: '../../static/yuxin.jpg'
        },
        {
          Id: 'ac3be199-8a48-11ec-bd6f-00163e0ce4c5',
          Title: '东升实验小学',
          ListImage: '../../static/dongshengshiyan.jpg'
        },
        {
          Id: 'af672d9a-8a48-11ec-bd6f-00163e0ce4c5',
          Title: '石油第二实验小学',
          ListImage: '../../static/shiyoufushu.jpg'
        },
        {
          Id: 'b2a84ea3-8a48-11ec-bd6f-00163e0ce4c5',
          Title: '永泰小学',
          ListImage: '../../static/yongtai.jpg'
        }
      ],
      swiperOption: {
        //显示分页

        slidesPerView:3,
        //设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        //自动轮播
        autoplay: {
          delay: 5000
        },
        //开启循环模式
        loop: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: true
      }
    }

  },methods:{
    detail(n){
      this.$router.push({ name:'companynews',query:{ num: n }})
    }
  },computed:{

  }

}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);

  box-sizing: border-box;

}
.schools_content{
  display: flex;
  flex-direction: column;
  margin: 1.2rem auto;

  width: 42rem;
  position: relative;
  .producetitle{
    h3{
      color: #131313;
      font-size: 0.8rem;
      font-family:SourceHanSansSC;
      text-align: center;
      font-weight: bold;
    }
    .newslinecontent{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 3rem;
      .newsline{
        height: 0.1rem;
        background: #F68C1C;
        width: 1.44rem;
      }

    }
  }
  .lunbotu{

    margin-top:1rem;
    background-repeat: no-repeat;
    height: 7.2rem;
    .contentswiper{
      height: 100%;
      .xuexiaoc{
        cursor: pointer;
        height: 7rem;
        width:13rem;
        float: left;
        margin:auto 0.4rem;
        border: 1px solid #F68C1C;
        border-radius:0.1rem;
        position: relative;
        img {
          height: 100%;
          width: 100%;
          border-radius:0.1rem;
        }
        .xuexiaozz{

          background-color: black;
          opacity: 0.5;
          height:1.1rem;
          width: 100%;
          bottom: 0;
          position: absolute;

        }

        h3{
          position: absolute;
          bottom: 0;
          font-size: 0.77rem;
          line-height: 0.5rem;
          color: #ffffff;
          width: 100%;
          text-align: center;

        }

      }
    }

  }


}
.swiper-slide {
  width: 100%;
  height:unset!important;

}
::v-deep .swiper-button-next{
  background:unset!important;;
  top: 6.2rem;
  right: -1.2rem;
  .nextbtn{
    z-index: 2;
    width: 1.6rem;
    height: 1.6rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
::v-deep .swiper-button-prev{
  background:unset!important;;
  top: 6.2rem;
  left: -2rem;

  .prevbtn{
    z-index: 2;
    width: 1.6rem;
    height: 1.6rem;
    img{
      width: 100%;
      height: 100%;
    }
  }

}
.schools_list{
 
  margin:2rem auto;
  margin-left: 8%;
  
 
  .schools_item{

    display: flex;
    flex-direction: row;
    height: 2.2rem;
    margin-left: 1.5rem;

    .schools_title{
      border: 1px solid #A0A0A0;
    width: 9.8rem;
      margin-right: 2.2rem;
      height: 1.7rem;
      border-radius: 0.1rem;
      background-color: transparent;
      h3{
        vertical-align:center;
        text-align: center;
        padding-top: 0.2rem;
        color: #333333;
        font-size: 0.77rem;
        font-family: SourceHanSansSC;
        a{
          color: unset;
        }
      }
    }
    .schools_title:hover{
      background-color: #46B872;
      border: unset;
    }
  }

}
.bottom{
  padding: 0;
  z-index: 999;
  position: fixed;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}

</style>